<template>
  <div class="personal-information">
    <mob-header
      :interaction="false"
      status="info"
      :username="true"
    ></mob-header>
    <div class="main">
      <div class="form">
        <yl-input v-model="formData.username" placeholder="请输入姓名"
          ><img src="../../assets/name.png" alt=""
        /></yl-input>
        <yl-input
          v-model="formData.phone"
          placeholder="请输入手机号"
          :disabled="true"
          ><img src="../../assets/phone.png" alt=""
        /></yl-input>
        <yl-input v-model="formData.organization" placeholder="请输入工作单位"
          ><img src="../../assets/company.png" alt=""
        /></yl-input>
        <yl-input
          v-model="formData.email"
          placeholder="请输入电子邮箱"
          :disabled="true"
          ><img src="../../assets/email.png" alt=""
        /></yl-input>
        <yl-picker
          v-model="formData.jobfunction"
          :dataList="jobFunctionData"
          placeholder="请选择您的工作职能"
        >
          <img src="../../assets/job.png" alt="" />
        </yl-picker>
        <yl-picker
          v-model="formData.interest"
          :multiple="true"
          :dataList="interestData"
          placeholder="请选择感兴趣的领域"
        >
          <img src="../../assets/interest.png" alt="" />
        </yl-picker>
        <yl-picker
          v-model="formData.enquiry"
          :dataList="enquiryData"
          placeholder="您是否需要询价或联系销售代表？"
        >
          <img src="../../assets/myAppointment.png" alt="" />
        </yl-picker>
      </div>
      <button class="comfirm" @click="comfirmFn()">保存</button>
    </div>
    <mob-footer-bar :active="3"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import YlInput from "../../components/yl-input.vue";
import YlPicker from "../../components/yl-picker.vue";
import { updateUserInfo, userInfo, wechatConfig } from "../../api/user";
// import { getToken } from "../../utils/auth";
import wechat from "../../utils/wxShare";
export default {
  components: {
    MobHeader,
    MobFooterBar,
    YlInput,
    YlPicker,
  },
  metaInfo: {
    title: "基本信息",
  },
  data() {
    return {
      formData: {
        id: null,
        username: "",
        phone: "",
        organization: "",
        email: "",
        jobfunction: "",
        interest: "",
        enquiry: "",
        // sourceTag: "123",
        // registerType: "123",
        // registerCourse: "123",
        // wxOpenId: "123",
        // device: "123",
      },
      jobFunctionData: [
        [
          { label: "行政 - 其他", value: "行政 - 其他" },
          { label: "行政 - 采购代理人", value: "行政 - 采购代理人" },
          {
            label: "生物信息学/信息技术人员",
            value: "生物信息学/信息技术人员",
          },
          { label: "临床医生", value: "临床医生" },
          { label: "主管", value: "主管" },
          { label: "实验室主任", value: "实验室主任" },
          { label: "实验室人员", value: "实验室人员" },
          { label: "非科研人员", value: "非科研人员" },
          { label: "其他医学专业人员", value: "其他医学专业人员" },
          { label: "合作伙伴", value: "合作伙伴" },
          { label: "病理学家", value: "病理学家" },
          { label: "医师", value: "医师" },
          { label: "首席研究员", value: "首席研究员" },
          { label: "教授", value: "教授" },
          { label: "学生", value: "学生" },
        ],
      ],
      interestData: [
        "农业研究",
        "动物测试",
        "应用微生物学",
        "自体免疫性疾病研究",
        "癌症研究",
        "心血管疾病研究",
        "细胞与分子生物学研究",
        "临床肿瘤学",
        "消费者基因组学",
        "遗传与复杂疾病研究",
        "遗传健康",
        "代谢疾病研究",
        "微生物学/病毒学研究",
        "神经科学研究",
        "非侵入性产前检测",
        "植物检测",
        "孕前与生育保健",
        "公共卫生",
        "罕见及遗传性疾病",
      ],
      enquiryData: [
        [
          { label: "是的，我需要", value: "是的，我需要" },
          {
            label: "不需要，发送产品资料即可",
            value: "不需要，发送产品资料即可",
          },
        ],
      ],
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    };
  },
  created() {
    this.getUserInfo();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    comfirmFn() {
      for (var key in this.formData) {
        if (this.formData[key] != "0" && !this.formData[key]) {
          this.$ylmessage.error("您的信息尚未填写，请补全信息");
          return;
        }
      }
      updateUserInfo(this.formData).then((res) => {
        if (res.code == 0) {
          this.$ylmessage.success("信息保存成功");
          setTimeout(() => {
            this.$router.replace({ path: "/personal" });
          }, 1500);
        }
      });
    },
    // 查询用户信息
    getUserInfo() {
      // if (getToken()) {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.formData.id = res.data.id;
          this.formData.username = res.data.username;
          this.formData.phone = res.data.phone;
          this.formData.organization = res.data.organization;
          this.formData.email = res.data.email;
          this.formData.jobfunction = res.data.jobfunction;
          this.formData.interest = res.data.interest;
          this.formData.enquiry = res.data.enquiry;
        } else if (res.code == 60000) {
          this.$router.replace({ path: "/login" });
        }
      });
      // } else {
      //   this.$router.replace({ path: "/login" });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-information {
  height: 100%;
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0.05rem 0.15rem;
    .form {
      padding: 0.25rem 0.2rem;
      background: #fff;
    }
    .comfirm {
      margin-top: 0.2rem;
      width: 100%;
      height: 0.4rem;
      font-size: 0.14rem;
      border: none;
      border-radius: 0.2rem;
      background: #49b67b;
      color: #fff;
    }
  }
}
</style>
